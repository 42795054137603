var buildShippingData = function buildShippingData(_ref) {
  var _deliveries$;
  var deliveries = _ref.deliveries,
    error = _ref.error,
    isClickedCalculateShipping = _ref.isClickedCalculateShipping,
    publish = _ref.publish,
    setErrorMessage = _ref.setErrorMessage,
    setDeliveryTypes = _ref.setDeliveryTypes,
    shippings = _ref.shippings,
    zipcode = _ref.zipcode;
  if (error) {
    setErrorMessage(error);
    publish('product:shipping:error', {
      error: error
    });
    return null;
  }
  var deliveriesOptions = deliveries == null ? void 0 : (_deliveries$ = deliveries[0]) == null ? void 0 : _deliveries$.modalities.map(function () {
    var _shippingTime$disclai, _shippingTime$disclai2, _shippingTime$value;
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      shippingTime = _ref2.shippingTime,
      id = _ref2.id,
      cost = _ref2.cost,
      type = _ref2.type;
    return {
      complement: shippingTime == null ? void 0 : (_shippingTime$disclai = shippingTime.disclaimers) == null ? void 0 : (_shippingTime$disclai2 = _shippingTime$disclai[0]) == null ? void 0 : _shippingTime$disclai2.message,
      description: shippingTime == null ? void 0 : shippingTime.description,
      id: id,
      price: cost == null ? void 0 : cost.customer,
      time: shippingTime == null ? void 0 : (_shippingTime$value = shippingTime.value) == null ? void 0 : _shippingTime$value.max,
      type: type,
      unit: shippingTime == null ? void 0 : shippingTime.unit
    };
  });
  setDeliveryTypes(deliveriesOptions);
  if (!isClickedCalculateShipping) {
    publish('product:shipping:change', {
      deliveries: deliveries,
      shipping: shippings,
      zipcode: zipcode
    });
  }
  publish('product:shipping:haschanged', {
    deliveries: deliveries,
    shipping: shippings,
    zipcode: zipcode
  });
  return deliveriesOptions;
};
export default buildShippingData;